@tailwind base;
@tailwind components;
@tailwind utilities;

*{
    font-family: 'Poppins', sans-serif;
}

body{
    overflow-x: hidden;
}

.selected{
    border-bottom: 2px solid black;
}

.selectedMobile{
    color: #40E0D0;
}


.drawer {
    position: fixed;
    top: -100%;
    background-color: black;
    height: 100%;
    width: 100%;
    transition: top 0.5s ease;
    display: flex;
    flex-direction: column;
    gap: 10px;
    z-index: 10;
}
  
.drawer.open {
    top: 0;
}